'use client';

import * as Sentry from '@sentry/nextjs';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogTrigger } from '@/components/ui/dialog';
import { Button } from '../ui/button';
import { getProviders } from 'sats-connect';
import { useEffect, useState } from 'react';
import { useLoading } from '../LoadingProvider';
import logger from '@monorepo/logger';
import DynamicConnect from './modules/DynamicConnect';
import LoadingIcon from '../LoadingIcon';
import { SupportedWallets } from '@/types';
const log = logger.getLogger('ConnectWallet');
export const ConnectWallet = () => {
  const [hasWallet, setHasWallet] = useState({
    [SupportedWallets.XVERSE]: false,
    [SupportedWallets.MAGIC_EDEN]: false,
    [SupportedWallets.UNISAT]: false,
    [SupportedWallets.PHANTOM]: false,
    [SupportedWallets.LEATHER]: false
  });
  const [modalOpen, setModalOpen] = useState(false);
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const {
    setIsLoading
  } = useLoading();
  useEffect(() => {
    // Check if the user is on Android mobile
    const isAndroidMobile = /Android.*Mobile/.test(navigator.userAgent);
    const providers = getProviders();
    log.debug('providers: ', providers);
    setIsLoading(false);
    if (isAndroidMobile) {
      Sentry.captureMessage(`providers: ${providers.map(value => value.id).join(',')}`);
      Sentry.captureMessage('window object', {
        extra: {
          window
        }
      });
    }
    setHasWallet({
      // NOTE: Hard-coding xverse to true as the getProviders array isn't returning anything for Xverse
      // on Android. If the user clicks the Xverse button without having Xverse installed,
      // a toast will still show and the user won't be able to connect.
      [SupportedWallets.XVERSE]: true,
      [SupportedWallets.UNISAT]: 'unisat' in window,
      [SupportedWallets.MAGIC_EDEN]: 'magicEden' in window,
      // temp hard-coded until we have support
      [SupportedWallets.PHANTOM]: 'phantom' in window,
      [SupportedWallets.LEATHER]: 'LeatherProvider' in window
    });
  }, []);
  return <>
      <Dialog open={modalOpen} onOpenChange={setModalOpen} data-sentry-element="Dialog" data-sentry-source-file="index.tsx">
        <DialogTrigger asChild data-sentry-element="DialogTrigger" data-sentry-source-file="index.tsx">
          <Button variant='default' size='xl' disabled={isAuthenticating} data-sentry-element="Button" data-sentry-source-file="index.tsx">
            {isAuthenticating ? 'Authenticating...' : 'Login'}
          </Button>
        </DialogTrigger>
        <DialogContent aria-describedby='Connect Wallet Modal' data-sentry-element="DialogContent" data-sentry-source-file="index.tsx">
          {isAuthenticating ? <></> : <DialogHeader>
              <DialogTitle>Connect</DialogTitle>
            </DialogHeader>}
          {isAuthenticating ? <div className='flex flex-col align-middle gap-4 items-center justify-center text-center'>
              <LoadingIcon />
              <p className='text-xs text-primary'>Sign the wallet messages to verify that you own this wallet...</p>
            </div> : <div className='flex flex-col gap-4'>
              {/* leaving this here for multiple wallet support in the near future */}
              {Object.values(SupportedWallets).map(key => {
            if (hasWallet[key]) {
              return <DynamicConnect key={key} setModalOpen={setModalOpen} setIsAuthenticating={setIsAuthenticating} walletName={key} />;
            }
          })}
            </div>}
        </DialogContent>
      </Dialog>
      <p className='text-xs text-muted-foreground -mt-4'>Use the BTC taproot wallet containing your ordinals</p>
    </>;
};