'use client';

import * as Sentry from '@sentry/nextjs';
import Image from 'next/image';
import { Button } from '@/components/ui/button';
import { useToast } from '@/hooks/useToast';
import { useRouter } from 'next/navigation';
import logger from '@monorepo/logger';
import { getAddress, AddressPurpose, BitcoinNetworkType, request } from 'sats-connect';
import { getNonce, login } from '@/actions';
import { AUTH_PAGE_ROUTES } from '@/config/authPageRoutes';
import useLocalStorage from '@/hooks/useLocalStorage';
import { AuthData } from '@monorepo/auth';
import { LOCAL_STORAGE_LAST_CONNECTED_WALLET } from '@/config';
import useWallets from '@/hooks/useWallets';
import { cn } from '@/lib/utils';
import { PUBLIC_PAGE_ROUTES } from '@/config/publicPageRoutes';
import { SupportedWallets } from '@/types';
const log = logger.getLogger('ConnectWallet/modules/DynamicConnect');
const WALLET_CONTENT: { [key in SupportedWallets]: {
  prettyName: string;
  image: {
    source: string;
    width: number;
    height: number;
  };
} } = {
  unisat: {
    prettyName: 'UniSat',
    image: {
      source: '/images/unisatlogo.svg',
      width: 100,
      height: 25
    }
  },
  xverse: {
    prettyName: 'Xverse',
    image: {
      source: '/images/xverselogo.webp',
      width: 80,
      height: 15
    }
  },
  magicEden: {
    prettyName: 'MagicEden',
    image: {
      source: '/images/magicedenwallet.png',
      width: 230,
      height: 50
    }
  },
  phantom: {
    prettyName: 'Phantom',
    image: {
      source: '/images/Phantom-Logo-White.svg',
      width: 115,
      height: 20
    }
  },
  leather: {
    prettyName: 'Leather',
    image: {
      source: '/images/leatherlogo.svg',
      width: 90,
      height: 20
    }
  }
};
const WALLET_MESSAGE = 'Address containing your Mining Pass Ordinal(s)';
const DynamicConnect = ({
  setModalOpen,
  setIsAuthenticating,
  walletName
}: {
  setModalOpen: (open: boolean) => void;
  setIsAuthenticating: (open: boolean) => void;
  walletName: SupportedWallets;
}) => {
  const {
    toast,
    dismiss
  } = useToast();
  const router = useRouter();
  const walletHelpers = useWallets();
  const [lastConnectedWalletValue, setLastConnectedWalletValue] = useLocalStorage(LOCAL_STORAGE_LAST_CONNECTED_WALLET);
  const walletContent = WALLET_CONTENT[walletName];
  const handleLogin = async ({
    msgSig,
    ordWalletAddress,
    pmtWalletAddress,
    nonce
  }: AuthData) => {
    const authResponse = await login({
      authData: {
        msgSig,
        ordWalletAddress,
        pmtWalletAddress,
        nonce
      }
    });
    const data = authResponse;
    log.debug('data', data);
    if (data.error) {
      setIsAuthenticating(false);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: `Address ending in ${ordWalletAddress.slice(-6)} is not a holder`
      });
      void router.push(PUBLIC_PAGE_ROUTES._no_access);
      return;
    }
    if (data.session) {
      setLastConnectedWalletValue(walletName);
      setModalOpen(false);
      log.debug('redirecting to home', data.session);
      toast({
        title: 'Success',
        description: 'Redirecting...'
      });
      void router.push(AUTH_PAGE_ROUTES._home);
    }
  };
  type SatsConnectWallets = Exclude<SupportedWallets, SupportedWallets.UNISAT | SupportedWallets.PHANTOM | SupportedWallets.LEATHER>;
  const authenticateWithSatsConnect = async (provider: SatsConnectWallets) => {
    // Implementation here is a bit more raw since the user hasn't set a preferred wallet (aka its not in localStorage)
    const walletHelperConfig: { [key in SatsConnectWallets]: {
      providerMethod: any;
      signMethod: any;
    } } = {
      [SupportedWallets.XVERSE]: {
        providerMethod: walletHelpers.getXverseProvider,
        signMethod: walletHelpers.signXverseMessage
      },
      [SupportedWallets.MAGIC_EDEN]: {
        providerMethod: walletHelpers.getMagicEdenProvider,
        signMethod: walletHelpers.signMagicEdenMessage
      }
    };
    const {
      providerMethod,
      signMethod
    } = walletHelperConfig[provider];
    try {
      await getAddress({
        getProvider: providerMethod as any,
        payload: {
          purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
          message: WALLET_MESSAGE,
          network: {
            type: BitcoinNetworkType.Mainnet
          }
        },
        onFinish: async response => {
          const {
            ordWalletAddress,
            pmtWalletAddress
          } = await walletHelpers.getMagicEdenOrXverseAddress(response);
          const {
            nonce,
            error
          } = await getNonce({
            ordWalletAddress
          });
          log.debug('nonceResponse: ', {
            nonce,
            error
          });

          // Check if nonce is found
          if (!nonce || error) {
            log.error('nonce not found');
            throw new Error('nonce not found');
          }
          log.debug('nonce', nonce);
          let signature;
          try {
            signature = await signMethod(nonce, ordWalletAddress);
          } catch (e: any) {
            setIsAuthenticating(false);
            toast({
              variant: 'default',
              description: 'User canceled signing'
            });
            return false;
          }
          if (!signature) {
            setIsAuthenticating(false);
            toast({
              variant: 'default',
              description: 'Something went wrong, please try again.'
            });
            return false;
          }

          // Attempte to login by sending back the signature
          await handleLogin({
            msgSig: signature.signedSignature,
            ordWalletAddress,
            pmtWalletAddress,
            nonce
          });
        },
        onCancel: () => {
          setIsAuthenticating(false);
          toast({
            variant: 'default',
            description: 'User canceled request'
          });
        }
      });
    } catch (e) {
      setIsAuthenticating(false);
      toast({
        variant: 'default',
        description: 'User canceled request'
      });
    }
  };
  const authenticateWithUnisat = async () => {
    try {
      // Ensure they're on the correct network
      await walletHelpers.ensureCorrectNetworkUnisat();

      // Get accounts
      const ordWalletAddress = await walletHelpers.getUnisatAddress();
      if (ordWalletAddress) {
        const initialMsg = await walletHelpers.signUnisatMessage(WALLET_MESSAGE, ordWalletAddress);
        if (!initialMsg?.signedSignature) {
          throw new Error('Signature not found');
        }
        const {
          nonce,
          error
        } = await getNonce({
          ordWalletAddress: ordWalletAddress
        });

        // Check if nonce is found
        if (!nonce || error) {
          log.error('nonce not found');
          throw new Error('nonce not found');
        }
        const nonceMsg = await walletHelpers.signUnisatMessage(nonce, ordWalletAddress);
        if (!nonceMsg?.signedSignature) {
          throw new Error('Signature not found');
        }
        await handleLogin({
          msgSig: nonceMsg.signedSignature,
          ordWalletAddress,
          pmtWalletAddress: ordWalletAddress,
          nonce
        });
      }
    } catch (e: any) {
      setIsAuthenticating(false);
      if (e.message) {
        toast({
          description: e.message
        });
      }
    }
  };
  const authenticateWithPhantom = async () => {
    try {
      // Get accounts
      const {
        ordWalletAddress,
        pmtWalletAddress
      } = await walletHelpers.getPhantomAddress();
      Sentry.captureMessage('Ord/Pmt Wallet Addresses', {
        extra: {
          wallets: [ordWalletAddress, pmtWalletAddress]
        }
      });
      if (!ordWalletAddress) {
        throw new Error('No ordinals address found');
      }
      log.debug('phantom ord / pmt: ', {
        ordWalletAddress,
        pmtWalletAddress
      });
      const initialMsg = await walletHelpers.signPhantomMessage(WALLET_MESSAGE, ordWalletAddress);
      if (!initialMsg?.signedSignature) {
        throw new Error('No signature found');
      }
      const {
        nonce,
        error
      } = await getNonce({
        ordWalletAddress: ordWalletAddress
      });
      log.debug('nonceResponse: ', {
        nonce,
        error
      });

      // Check if nonce is found
      if (!nonce || error) {
        log.error('nonce not found');
        throw new Error('nonce not found');
      }
      log.debug('nonce', nonce);
      const nonceMsg = await walletHelpers.signPhantomMessage(nonce, ordWalletAddress);
      if (!nonceMsg?.signedSignature) {
        throw new Error('No signature found');
      }
      await handleLogin({
        msgSig: nonceMsg.signedSignature,
        ordWalletAddress,
        pmtWalletAddress,
        nonce
      });
    } catch (e: any) {
      log.error(e);
      Sentry.captureException(e, {
        extra: {
          location: 'authenticateWithPhantom()'
        }
      });
      setIsAuthenticating(false);
      if (e.message) {
        toast({
          description: e.message
        });
      }
    }
  };
  const authenticateWithLeather = async () => {
    try {
      // Get accounts
      const {
        ordWalletAddress,
        pmtWalletAddress
      } = await walletHelpers.getLeatherAddress();
      if (ordWalletAddress) {
        const initialMsg = await walletHelpers.signLeatherMessage(WALLET_MESSAGE);
        if (!initialMsg?.signedSignature) {
          throw new Error('Signature not found');
        }
        const {
          nonce,
          error
        } = await getNonce({
          ordWalletAddress: ordWalletAddress
        });
        log.debug('nonceResponse: ', {
          nonce,
          error
        });

        // Check if nonce is found
        if (!nonce || error) {
          log.error('nonce not found');
          throw new Error('nonce not found');
        }
        log.debug('nonce', nonce);
        const nonceMsg = await walletHelpers.signLeatherMessage(nonce);
        if (!nonceMsg?.signedSignature) {
          throw new Error('Signature not found');
        }
        await handleLogin({
          msgSig: nonceMsg.signedSignature,
          ordWalletAddress,
          pmtWalletAddress,
          nonce
        });
      }
    } catch (e: any) {
      setIsAuthenticating(false);
      const errorMsg = e.message || e.error.message;
      if (errorMsg) {
        toast({
          description: errorMsg
        });
      }
    }
  };
  const authenticate = async () => {
    dismiss();
    setIsAuthenticating(true);
    try {
      if (walletName === SupportedWallets.UNISAT) {
        // Unisat does its own thing
        await authenticateWithUnisat();
      } else if (walletName === SupportedWallets.PHANTOM) {
        Sentry.captureMessage('authenticating with phantom');
        await authenticateWithPhantom();
      } else if (walletName === SupportedWallets.LEATHER) {
        await authenticateWithLeather();
      } else {
        // Xverse and MagicEden both use sats-connect
        await authenticateWithSatsConnect(walletName);
      }
    } catch (e) {
      Sentry.captureException(e, {
        extra: {
          location: 'authenticate()'
        }
      });
      setIsAuthenticating(false);
      toast({
        variant: 'destructive',
        title: 'Error',
        description: `No ${walletContent.prettyName} wallet installed`
      });
    }
  };
  return <Button onClick={async e => {
    e.preventDefault();
    e.stopPropagation();
    await authenticate();
  }} size='lg' variant='outline' className={cn('relative', lastConnectedWalletValue === walletName ? 'border border-primary' : '')} data-sentry-element="Button" data-sentry-component="DynamicConnect" data-sentry-source-file="DynamicConnect.tsx">
      <Image src={walletContent.image.source} alt={`${walletContent.prettyName} Wallet Logo`} width={walletContent.image.width} height={walletContent.image.height} data-sentry-element="Image" data-sentry-source-file="DynamicConnect.tsx" />
      {lastConnectedWalletValue === walletName ? <span className='absolute -right-[1px] -top-2 text-[10px] uppercase text-primary bg-background border border-primary leading-snug px-0.5'>
          Last Used
        </span> : <></>}
    </Button>;
};
export default DynamicConnect;